<template>
    <!-- страница все звонки -->
      <PageHeader :title="title" />

      <!-- {{ requests }} -->
    <!-- 
        Компонент таблиці
        - @search - подія, яка спрацьовує при пошуку у таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - :removeitem - параметр-прапорець, що дозволяє чи забораняє видалення записів з таблиці
        - :tabs - параметр-об'єкт із конфігурацією вкладок таблиці
        - :addtabs - параметр-прапорець, що вказує, чи відображати кнопку для додавання вкладок
        - @addCategory - подія, що спрацьовує при додаванні нової категорії
        - @removeCategory - подія, що спрацьовує при видаленні категорії
        - @remove - подія, яка спрацьовує при видаленні запису з таблиці
        - @create - подія, яка спрацьовує при створенні нового співробітника
        - @open - подія, яка спрацьовує при відкритті картки співробітника
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
        - @clearFilter - подія, яка спрацьовує при очищенні фільтрів таблиці
        - @changeTab - подія, яка спрацьовує при зміні вкладки таблиці
        - :removeitem - пропс на видалення предмету
    -->
    <tablecustom 
      @search="searchB" 
      :dataParams="dataParams"
      :objParams="objParams"
      :columns="columns" 
      :rows="rows" 
      :pages = "this.objPages"
      :createdform="this.perms[101] || this.perms[2000]"
      :showpag="true"
      @sortBy="sortBy"
      :tabs="othertabs"
      :addtabs="true"
      @addCategory="showFilterBox = true"
      @removeCategory="removeCategory"
      @create="create()" 
      @changeFilter="changeFilter" 
      @clearFilter="clearFilter" 
      @open="open" 
      :removeitem="this.perms[103] || this.perms[2000]"
      @remove="remove" 
      @getdata="getdata"
      @changelimit="changelimit"
      @removeAll="removeAll"
      @changeTab="changeTab"
    /> 

    <viewRequest 
      v-if="this.showRequest == true"
      :shortapplic = "this.objCard"
      :ifPrevopen="ifPrevopen" 
      :ifNextopen="ifNextopen" 
      @prevOpen="prevOpen" 
      @nextOpen="nextOpen"
      @close="this.showRequest = false"
    />

</template>
  
<script>
import viewRequest from './view/index.vue'
import PageHeader from "@/components/page-header";
import tablecustom from '@/components/globaltable/index';
import { TriggerCalls } from '@/API.js'
import { storeS } from "@/store";
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { mutatePriority, mutateStatus, mutateTypeHistoryAcc } from '@/usabilityScripts/globalMutate.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiTriger = new TriggerCalls()  

export default {
  components: {
    PageHeader,
    tablecustom,
    viewRequest
  },
  data() {
    return {
      ifNextopen: "",
      ifPrevopen: "",
      rows: [],
      othertabs: [],
      columns: [],
      objParams:{
        page: '1',
        pagelimit: '10',
        search: '',
        status: '',
        priority: '',
        workerId: '',
        inJobWorkerId: '',
        pr1: '',
        pr2: '',
        actionCall: '',
      },
      columns: [
        {
          name: 'ID',
          text: "requestId",
          align: "left",
          status: false,
        },
        {
          name: this.$t('Application_type'),
          text: "type",
          align: "left",
          status: true,
          mutate: (item, obj) => this.mutateTypeHistoryAcc(item).name,
          mutateClass: (item, obj) => this.mutateTypeHistoryAcc(item).color
        },
        {
          name: this.$t("phone_num"),
          text: "userPhone",
          align: "right",
          status: true,
        },
        {
          name: this.$t("Status"),
          text: 'status',
          align: "left",
          mutate: (item, obj) => this.mutateStatus(item, obj).name,
          mutateClass: (item, obj) => this.mutateStatus(item, obj).color
        },
        {
          name: this.$t("priority"),
          text: "priority",
          align: "right",
          status: true,
          mutate: (item) => this.mutatePriority(item).name,
          mutateClass: (item) => this.mutatePriority(item).bg
        },
        {
          name: this.$t("date_create"),
          text: "createDatetime",
          align: "right",
          status: true,
        },
        {
          name: this.$t("added"),
          text: "createWorkerId",
          align: "right",
          status: true,
          mutate: (item) => item ? this.nameWorker(item) : this.checks['024'] ? 'Jesus' : '-' 
        },
      ],
      dataParams: {
        status: true,
        page: "trigger_calls"
      },
      objPages: {},
      objCard: {},
      showRequest: false
    }
  },
  methods: {
    open(e) {
      apiTriger.getTriggerCall(e.requestId).then(result => {
        if(result.status == 'done') {
          this.objCard = result.data
          this.showRequest = true
        } else {
          this.$toast.error(this.$t('error') + " " + (result.error[0] ? result.error[0] : ''));

          var errorText = 'Помилка отримання заявки тріггера'; 
          var statusRequest = result.status;
          var api = 'getTriggerCall';
          var fileRequest = 'src/views/calls/trigger/all.vue';
          var params = e.requestId
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
    mutateTypeHistoryAcc(e) {
      return mutateTypeHistoryAcc(e)
    },
    mutateStatus(e, obj){
      return mutateStatus(e, obj);
    },
    mutatePriority(e){
      return mutatePriority(e);
    },
    nameWorker(item){
      return nameWorker(item)
    },
    getdata() {
      apiTriger.getTriggerCalls().then(result => {
        if(result.status == 'done') {
          this.rows = result.data.items
          this.objPages = result.data;
        } else {
          this.$toast.error(this.$t('error') + " " + (result.error[0] ? result.error[0] : ''));

          var errorText = 'Помилка отримання заявок тріггерів'; 
          var statusRequest = result.status;
          var api = 'getTriggerCalls';
          var fileRequest = 'src/views/calls/trigger/all.vue';
          var params = ""
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
    prevOpen(e){
      var current_id = e;
      var prev_id = ""; 

      for(var value in this.rows){
        if(this.rows[value].requestId == current_id){
          prev_id = this.rows[Number(value)-Number('1')].requestId;
        }
      }
      this.open(prev_id);
    },
    nextOpen(e){
      var current_id = e;
      var next_id = "";

      for(var value in this.rows){
        if(this.rows[value].requestId == current_id){
          next_id = this.rows[Number(value)+Number('1')].requestId;
        }
      }
      this.open(next_id);
    },
  },
  computed: {
    perms() {
      return storeS.perms
    },
    checks() {
      return storeS.checks
    }
  },
  created() {
    this.getdata()
  }
}


</script>
  
  <style scoped>
  .tr_etner:hover {
    cursor: pointer;
    background: #f3f3f9;
  }
  .howto_block {
    margin-left:10px;
  }
  .howto_block i {
    font-size: 23px;
    opacity: 0.5;
  }
  .howto_block i:hover{
    opacity: 1;
  }
  
  .howto_block {
    position: relative;
    display: inline-block;
  }
  
  .howto_block .tooltiptext {
    visibility: hidden;
    position: absolute;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    margin-left: 10px;
  }
  
  .howto_block .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -7px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
  }
  
  .howto_block:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  </style>
  